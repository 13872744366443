<script setup>
import { inject } from 'vue';

const store = inject('store');
</script>

<template>
    <div class="form__row">
        <div class="form__field">
            <label class="label" for="">Message</label>
            <input type="text" class="text-input" v-model="store.activeStep.message">
        </div>
    </div>
</template>
