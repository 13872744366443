import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "modal", // The modal(s)
        "data",  // element where we want to transfer data as value attribute
    ];

    initialize() {
        this.modalTrigger = null; // The button that opened the modal
    }

    connect() {
        if (this.hasModalTarget) {
            this.modalTargets.every((modal) => {
                if (modal.classList.contains('is--visible')) {
                    document.body.classList.add('scroll--disabled');

                    this.addFocusTrap(modal);

                    // Stop iterating over the modals - we already found one that is visible
                    return false;
                }

                // Continue iterating over the modals
                return true;
            });
        }

        document.addEventListener('modal:hide-all-modals', (e) => this.hideAll(e, true));

        // Listen for modal:open events from other components
        document.addEventListener('modal:open', (e) => this.showFromEvent(e));
    }

    show(e) {
        // Get the element that opened the modal, so that we can later set the focus to it when the modal is closed
        this.modalTrigger = e.currentTarget.id !== '' ? document.getElementById(e.currentTarget.id) : null;

        this.modalTargets.every((modal) => {
            if (modal.id === e.params.id) {
                // Show and enable the modal
                modal.classList.add('is--visible');
                modal.removeAttribute('inert');
                document.body.classList.add('scroll--disabled');

                this.addFocusTrap(modal);

                // Passing data to element
                if (e.params.value !== null && this.hasDataTarget) {
                    this.dataTargets.forEach(dataTarget => dataTarget.value = e.params.value);
                }

                // Stop iterating over the modals - we found the one that should be shown
                return false;
            }

            // Continue iterating over the modals
            return true;
        });
    }

    showFromEvent(e) {
        const modalId = e.detail.id;
        const modalValue = e.detail.value || null;

        this.modalTargets.every((modal) => {
            if (modal.id === modalId) {
                modal.classList.add('is--visible');
                modal.removeAttribute('inert');
                document.body.classList.add('scroll--disabled');

                this.addFocusTrap(modal);

                // Passing data to element
                if (modalValue !== null && this.hasDataTarget) {
                    this.dataTargets.forEach(dataTarget => dataTarget.value = modalValue);
                }

                return false;
            }
            return true;
        });
    }

    hideAll(e, force = false) {
        const keyHasBeenPressed = e.type === 'keydown';
        const escapeKeyHasBeenPressed = e.keyCode === 27;

        if (force === false) {
            // We're only interested in the Escape key
            if (keyHasBeenPressed && !escapeKeyHasBeenPressed){
                return;
            }
        }

        document.body.classList.remove('scroll--disabled');

        this.modalTargets.forEach(function (modal) {
            // Hide and disable the modal
            modal.classList.remove('is--visible');
            modal.setAttribute('inert', '');

            // Remove the focus trap when the modal is closed
            const focusDiv = modal.querySelector('.modal-focus');

            if (focusDiv !== null) {
                focusDiv.remove();
            }
        });


        // Return the focus to the button that opened the modal
        if (this.modalTrigger !== null) {
            this.modalTrigger.focus();
            this.modalTrigger = null;
        }
    }

    showSocialBuzz() {
        console.debug(this.modalTargets)
        console.debug('Opening social buzz modal...')

        const socialBuzzModal = document.getElementById('socialBuzzModal')

        console.debug(socialBuzzModal)

        if (socialBuzzModal !== null) {
            socialBuzzModal.removeAttribute('inert')
            socialBuzzModal.classList.add('is--visible')
        } else {
            console.debug('Could not find social buzz modal, guess it is not enabled...')
        }
    }

    addFocusTrap(modal) {
        // Focus the modal dialog when opened
        const modalDialog = modal.querySelector('.modal__dialog');

        // wait until the transition is finished
        setTimeout(function() {
            modalDialog.focus();
        }, 100);

        // Add a focus trap, so the user focus stays within the modal
        let focusDiv = document.createElement('div');
        focusDiv.setAttribute("tabindex", "0");
        focusDiv.classList.add("modal-focus");
        modal.appendChild(focusDiv);

        // Return the focus to the modal-dialog when the last element is focused (focus trap)
        const modalFocus = modal.querySelector('.modal-focus');
        modalFocus.addEventListener('focus', function(event) {
            modalDialog.focus();
        });
    }
}
