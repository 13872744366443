import { ulid } from 'ulid';

const stepSchemas = {
    NotifySuccessManagerStep: () => ({
        id: ulid(),
        type: 'NotifySuccessManagerStep',
        message: '',
    }),
    SendEmailStep: () => ({
        id: ulid(),
        type: 'SendEmailStep',
        body: {
            html: '',
            json: {
                type: 'doc',
                content: [],
            },
        },
    }),
};

export default stepSchemas;
