<script setup>
import {inject} from "vue";
import AddBlockButton from "./AddBlockButton.vue";
import Block from "./Block.vue";

const store = inject('store');
</script>

<template>
    <div class="journey-builder">
        <div class="journey-builder__inner">
            <div class="journey-blocks">
                <template v-for="(block, index) in store.journey.blocks" :key="block.id">
                    <Block :block-index="index"/>
                </template>
            </div>
            <AddBlockButton/>
        </div>
    </div>
</template>
