import {Controller} from '@hotwired/stimulus';
import {showSaveSuccessAnimation} from "../lib/submit_button_disabler";

export default class extends Controller {

    connect() {
        this.element.addEventListener('live-component:form-success-animation', () => showSaveSuccessAnimation(this.element));
    }

    disconnect() {
        this.element.removeEventListener('live-component:form-success-animation', () => showSaveSuccessAnimation(this.element));
    }
}