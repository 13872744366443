import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
     static targets = [
          "recipientGroup", // <select> for recipientGroup
          "communityContainer", // <div> container of community select
          "recipients", // textarea for recipients
          "recipientsContainer", // <div> container of recipients input textarea
          "community", // <select> for community
          "preview", // preview switch
          "textarea", // all other textareas (content & salutation)
     ];

     connect() {
          this.recipientGroupChanged(null);
     }

     recipientGroupChanged(event) {

          if (event !== null) {
               event.stopPropagation();
          }

          if (this.recipientGroupTarget.value === 'USERS_IN_SPECIFIC_COMMUNITY') {
               this.communityContainerTarget.removeAttribute('hidden');
               this.communityTarget.setAttribute('required', 'required');

               this.recipientsContainerTarget.setAttribute('hidden', 'hidden');
               this.recipientsTarget.removeAttribute('required');
          } else if (this.recipientGroupTarget.value === 'CUSTOM_LIST') {

               if (this.hasCommunityContainerTarget) {
                    this.communityContainerTarget.setAttribute('hidden', 'hidden');
               }
               if (this.hasCommunityTarget) {
                    this.communityTarget.removeAttribute('required');
               }

               this.recipientsContainerTarget.removeAttribute('hidden');
               this.recipientsTarget.setAttribute('required', 'required');
          } else if (this.recipientGroupTarget.value === 'ALL_ACTIVE_USERS') {
               this.communityContainerTarget.setAttribute('hidden', 'hidden');
               this.communityTarget.removeAttribute('required');

               this.recipientsContainerTarget.setAttribute('hidden', 'hidden');
               this.recipientsTarget.removeAttribute('required');
          } else if (this.recipientGroupTarget.value === 'ALL_ACTIVE_MEMBERS_OF_THIS_COMMUNITY') {
               this.recipientsContainerTarget.setAttribute('hidden', 'hidden');
               this.recipientsTarget.removeAttribute('required');
          }
     }

     togglePreview(e) {
          const previewSwitch = e.target;
          const parentPreview = previewSwitch.closest('.email');

          if (previewSwitch.checked) {
               parentPreview.classList.add('is--preview');
               parentPreview.classList.add('is--loading');
               this.setTextAreaHeight(parentPreview);

               setTimeout(() => {
                    parentPreview.classList.remove('is--loading');
               }, 1000);
          } else {
               parentPreview.classList.remove('is--preview');
               parentPreview.classList.add('is--loading');
               this.removeTextAreaHeight(parentPreview);

               setTimeout(() => {
                    parentPreview.classList.remove('is--loading');
               }, 1000);
          }
     }

     // when in preview mode, the textarea needs the height as inline style
     // because it is possible that the content overflows the initial height
     setTextAreaHeight(parentPreview) {
          let textAreas = parentPreview.querySelectorAll('textarea');

          textAreas.forEach(textarea => {
               let textHeight = textarea.scrollHeight;
               textarea.style.height = textHeight + "px";
          });
     }

     removeTextAreaHeight(parentPreview) {
          let textAreas = parentPreview.querySelectorAll('textarea');

          textAreas.forEach(textarea => {
               if (!textarea.hasAttribute('data-ck-editor-target')) {
                    textarea.removeAttribute("style");
               }
          });
     }
}
