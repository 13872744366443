<script setup>
// TODO: Lint / codestyle check for Vue files

import {provide} from 'vue';
import Actions from "./components/Actions/Actions.vue";
import Blocks from "./components/Navigation/Block/Blocks.vue";
import MainArea from "./components/MainArea/MainArea.vue";
import useJourneyStore from "./store/useJourneyStore";

const {initialJourney, saveUrl} = defineProps(['initialJourney', 'saveUrl']);
const store = useJourneyStore(initialJourney, saveUrl);

provide('store', store);
</script>

<template>
  <div class="journey">
    <Blocks/>
    <MainArea/>
    <Actions/>
  </div>
</template>
