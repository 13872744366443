<script setup>
import { computed, inject, ref } from "vue";

const store = inject('store');
const {blockIndex} = defineProps(['blockIndex']);
const block = computed(() => store.journey.blocks[blockIndex]);
const blockLabel = computed(() => block.value.after === 0 ? 'Immediately' : 'After ' + block.value.after + ' days');
const editedAfter = ref(block.value.after);

const cancelEditing = () => {
    editedAfter.value = block.value.after;
    store.clearBlockEditing();
};
</script>

<template>
    <div>
        <label v-show="store.editedBlockIndex !== blockIndex" @click="store.editBlock(blockIndex)">{{blockLabel}}</label>
        <div v-show="store.editedBlockIndex === blockIndex">
            <input v-model.number="editedAfter"/>
            <button class="btn btn--primary btn--xs" @click="store.updateBlock(blockIndex, editedAfter)">OK</button>
            <button class="btn btn--secondary btn--xs" @click="cancelEditing()">Cancel</button>
        </div>
    </div>
</template>
