<script setup>
import { inject } from "vue";

const store = inject('store');
const { blockIndex } = defineProps(['blockIndex']);
</script>

<template>
    <div class="journey-step__actions">
        <button class="btn btn--secondary btn--xs" @click="store.addStep(blockIndex, 'NotifySuccessManagerStep')">Add "Notify Success Manager" step</button>
        <button class="btn btn--secondary btn--xs" @click="store.addStep(blockIndex, 'SendEmailStep')">Add "Send email" step</button>
    </div>
</template>
