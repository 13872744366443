<template>
  <div style="padding: 10px; border-radius: 10px; background: #f2f2f5; margin-bottom: 10px;">
    Body:
    <label v-if="editor !== null">
        <input
            type="checkbox"
            @click="editor.chain().focus().toggleBold().run()"
            :checked="editor.isActive('bold')">
        Bold
    </label>
  </div>
    <editor-content :editor="editor"/>
</template>

<script>
// See https://tiptap.dev/docs/editor/getting-started/install/vue3

import { Editor, EditorContent } from '@tiptap/vue-3';
import Bold from '@tiptap/extension-bold';
import Document from '@tiptap/extension-document';
import Gapcursor from '@tiptap/extension-gapcursor';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import suggestion from './suggestion.js'

export default {
    components: {
        EditorContent,
    },

    props: {
        modelValue: {
            type: Object,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            editor: null,
        };
    },

    watch: {
        modelValue(value) {
            const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value.json);
            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value.json, false);
        },
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                Bold,
                Document,
                Gapcursor,
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    suggestion,
                }),
                Paragraph,
                Text,
            ],
            content: this.modelValue.json,
            onUpdate: () => {
                this.$emit('update:modelValue', {
                    html: this.editor.getHTML(),
                    json: this.editor.getJSON(),
                });
            },
        });
    },

    beforeUnmount() {
        this.editor.destroy();
    },
}
</script>