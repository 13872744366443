import {Controller} from '@hotwired/stimulus'
import axios from "axios";
import Cropper from 'cropperjs/dist/cropper';
import {disableSubmitButtons, enableSubmitButtons, showSaveSuccessAnimation} from "../lib/submit_button_disabler";

export default class extends Controller {
     static targets = [
          "preview",
          "dropzone",
          "form",
          "cropperWrapper",
          'submitButton',
          "cancelButton",
          'closeCardButton',
          "deleteButton",
          "avatarWrapper",
          'errorMessage',
          "dropzoneError",
          "dropzoneCancel",
     ];

     initialize() {
          this.cropper = null;
     }

     connect() {
          this.boundPreviewFile = this.previewFile.bind(this);
          this.dropzoneTarget.addEventListener("change", this.boundPreviewFile);

          this.boundCancelCrop = this.cancelCrop.bind(this);
          this.cancelButtonTarget.addEventListener('click', this.boundCancelCrop);

          if (this.hasDeleteButtonTarget) {
               this.boundDeleteImage = this.deleteImage.bind(this);
               this.deleteButtonTarget.addEventListener('click', this.boundDeleteImage);
          }

          this.boundFormSubmit = this.formSubmit.bind(this);
          this.formTarget.addEventListener("submit", this.boundFormSubmit);

          // add aria-hidden true to dropzone placeholder
          const dropzonePlaceholder = document.querySelector('.dropzone-placeholder');
          dropzonePlaceholder.setAttribute('aria-hidden', 'true');
          // remove the placeholder attribute from the file input - we use aria-label instead.
          this.dropzoneTarget.removeAttribute('placeholder');
     }

     disconnect() {
          this.dropzoneTarget.removeEventListener("change", this.boundPreviewFile);
          this.formTarget.removeEventListener("submit", this.boundFormSubmit);
     }

     // paint a new canvas without corners, so we get a real round image
     getRoundedCanvas(sourceCanvas) {
          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          let width = sourceCanvas.width;
          let height = sourceCanvas.height;

          canvas.width = width;
          canvas.height = height;
          context.imageSmoothingEnabled = true;
          context.imageSmoothingQuality = 'high';
          context.drawImage(sourceCanvas, 0, 0, width, height);
          context.globalCompositeOperation = 'destination-in';
          context.beginPath();
          context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
          context.fill();
          return canvas;
     }

     formSubmit(event) {
          event.preventDefault();
          disableSubmitButtons(new CustomEvent(event, {detail: {formSubmission: {submitter: this.submitButtonTarget}, success: true}}));

          let data = new FormData(this.formTarget);
          this.cropperWrapperTarget.classList.add("is--cropping");  // add cropping class, to disable mouse events on cropping area

          if (this.cropper !== null) {
               let croppedCanvas = this.cropper.getCroppedCanvas({
                    width: 700,
                    height: 700,
                    imageSmoothingEnabled: true,
                    imageSmoothingQuality: 'high'
               });
               let roundedCanvas = this.getRoundedCanvas(croppedCanvas);

               roundedCanvas.toBlob((blob) => {
                    data.append('phoenix_picture_change_form[profileImage]', blob);

                    axios({
                         method: 'post',
                         url: this.formTarget.action,
                         data: data,
                         headers: {'X-Requested-With': 'XMLHttpRequest'}
                    })
                        .then((response) => {
                             this.updatePage(response.data, this.submitButtonTarget);
                        })
                        .catch((error) => {
                             console.log(error)
                             this.cropperWrapperTarget.classList.remove('is--cropping'); // remove cropping class
                        });
               })
          } else {
               axios({
                    method: 'post',
                    url: this.formTarget.action,
                    data: data,
                    headers: {'X-Requested-With': 'XMLHttpRequest'}
               })
                   .then((response) => {
                        this.updatePage(response.data, this.submitButtonTarget);
                   })
                   .catch((error) => {
                        console.log(error);
                        this.cropperWrapperTarget.classList.remove('is--cropping'); // remove cropping class
                   });
          }
     }

     previewFile() {
          let file = this.dropzoneTarget.files[0];
          this.dropzoneTarget.parentElement.classList.add("is--loading"); // show loading state on dropzone while file gets read
          this.dropzoneCancelTarget.classList.add('is--visible');

          if (this.validate(file)) {
               this.dropzoneErrorTarget.classList.add('is--visible');
               this.dropzoneTarget.parentElement.classList.remove("is--loading");
               this.dropzoneCancelTarget.classList.remove('is--visible');
               this.dropzoneController.clear();
               return
          }

          let reader = new FileReader();

          reader.addEventListener('load', (event) => {
               if (this.isFirefoxSVG(file)) {
                    this.previewTarget.src = this.createBase64SVG(reader);
               } else {
                    this.previewTarget.src = reader.result;
               }

               if (this.cropper) {
                    this.cropper.destroy();
               }
               this.cropper = new Cropper(this.previewTarget, {
                    aspectRatio: 1,
                    viewMode: 1,
                    data: { //define cropbox size
                         width: 240,
                         height: 240,
                    },
                    minCropBoxWidth: 60,
                    minCropBoxHeight: 60,
                    cropBoxResizable: true,
               });

               this.toggleFrom();
          }, false);

          // resize the CropBoxData after the cropper is ready, otherwise it will respond to the image size
          this.previewTarget.addEventListener('ready', function () {
               this.cropper.setCropBoxData({
                    width: 240,
                    height: 240,
               });
          });

          if (file) {
               if (this.isFirefoxSVG(file)) {
                    reader.readAsText(file);
               } else {
                    reader.readAsDataURL(file);
               }
          }
     }

     cancelCrop() {
          this.cropper.destroy();
          this.cropper = null;
          this.dropzoneController.clear();
          this.dropzoneTarget.parentElement.classList.remove("is--loading"); // remove loading state on dropzone before it is visible again
          this.dropzoneCancelTarget.classList.remove('is--visible');
          this.closeCardButtonTarget.removeAttribute('hidden');
          this.cancelButtonTarget.classList.remove('is--visible');
          this.toggleFrom();
     }

     cancelFileDrop() {
          this.dropzoneController.clear();
          this.dropzoneTarget.parentElement.classList.remove("is--loading");
          this.dropzoneCancelTarget.classList.remove('is--visible');
          this.dropzoneTarget.value = '';
     }

     toggleFrom() {
          if (this.avatarWrapperTarget.classList.contains('is--hidden')) {
               this.avatarWrapperTarget.classList.remove('is--hidden');
               this.cropperWrapperTarget.classList.remove('is--visible');
          } else {
               this.cropperWrapperTarget.classList.add("is--visible");
               this.avatarWrapperTarget.classList.add('is--hidden');
               this.closeCardButtonTarget.setAttribute('hidden', true);
               this.cancelButtonTarget.classList.add('is--visible');
          }
     }

     validate(file) {
          return file['type'].split('/')[0] !== 'image';
     }

     // check if its svg in firefox browser
     isFirefoxSVG(file) {
          return file['type'] === 'image/svg+xml' && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
     }

     deleteImage() {
          disableSubmitButtons(new CustomEvent(event, {detail: {formSubmission: {submitter: this.deleteButtonTarget}, success: true}}));
          this.deleteButtonTarget.setAttribute('disabled', '');
          axios({
               method: 'DELETE',
               url: '/my-profile/delete-profile-image',
               headers: {'X-Requested-With': 'XMLHttpRequest'}
          })
              .then((response) => {
                   this.updatePage(response.data, this.deleteButtonTarget);
              })
              .catch((error) => {
                   console.log(error)
              });
     }

     // rewrite HTML document
     updatePage(newContent, submitter) {
          newContent = new DOMParser().parseFromString(newContent, 'text/html');
          enableSubmitButtons(new CustomEvent('', {detail: {formSubmission: {submitter: submitter}, success: true}}));
          const phoenixPictureChangeForm = document.getElementById('phoenix_picture_change_form')
          phoenixPictureChangeForm.innerHTML = newContent.getElementById('phoenix_picture_change_form').innerHTML;

          // update all profile image on other places like sidebar & mobile navigation
          document.querySelector('#header-dropdown-user-image').src = newContent.querySelector("[data-avatar-update-target='profileAvatar']").src;
          document.querySelector('#phoenix_picture_change_form .profile-picture__img').src = newContent.querySelector("#phoenix_picture_change_form .profile-picture__img").src;

          showSaveSuccessAnimation(phoenixPictureChangeForm);
     }

     // Create base64 svg and set width/height attributes for Firefox svg bug see -https://bugzilla.mozilla.org/show_bug.cgi?id=700533#c39
     createBase64SVG(reader) {
          let svgDocument = new DOMParser().parseFromString(reader.result, "image/svg+xml");
          svgDocument.activeElement.setAttribute('width', svgDocument.documentElement.width.baseVal.value.toString());
          svgDocument.activeElement.setAttribute('height', svgDocument.documentElement.height.baseVal.value.toString());
          let svgElement = new XMLSerializer().serializeToString(svgDocument.activeElement);

          return 'data:image/svg+xml;base64,' + window.btoa(svgElement);
     }

     get dropzoneController() {
          return this.application.getControllerForElementAndIdentifier(this.dropzoneTarget.parentNode, "symfony--ux-dropzone--dropzone");
     }

     // cropper functions for keyboard users
     zoomIn() {
          this.cropper.zoom(0.1);
     }

     zoomOut() {
          this.cropper.zoom(-0.1);
     }

     moveLeft() {
          this.cropper.move(-10, 0);
     }

     moveRight() {
          this.cropper.move(10, 0);
     }

     moveUp() {
          this.cropper.move(0, -10);
     }

     moveDown() {
          this.cropper.move(0, 10);
     }
}