<script setup>
import { computed, inject } from "vue";
import EditStepButton from "./EditStepButton.vue";
import NotifySuccessManagerStep from "./NotifySuccessManagerStep.vue";
import RemoveStepButton from "./RemoveStepButton.vue";
import SendEmailStep from "./SendEmailStep.vue";

const store = inject('store');
const { blockIndex, stepIndex } = defineProps(['blockIndex', 'stepIndex']);
const step = computed(() => store.journey.blocks[blockIndex].steps[stepIndex]);
const border = computed(() => store.activeBlockIndex === blockIndex && store.activeStepIndex === stepIndex ? '1px solid red' : null);
</script>

<template>
    <div class="journey-step" :style="{ border: border}">
        <b>{{ step.type }}</b>

        <div v-if="step.type === 'SendEmailStep'">
            <SendEmailStep :block-index="blockIndex" :step-index="stepIndex"/>
        </div>
        <div v-else-if="step.type === 'NotifySuccessManagerStep'">
            <NotifySuccessManagerStep :block-index="blockIndex" :step-index="stepIndex" />
        </div>
        <EditStepButton :block-index="blockIndex" :step-index="stepIndex"/>
        <RemoveStepButton :block-index="blockIndex" :step-index="stepIndex"/>
    </div>
</template>
