import {Controller} from "@hotwired/stimulus";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {MysteryMindsTheme, setLocale} from "../lib/amcharts_config";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

let barChart = null;

export default class extends Controller {
    static values = {chart: Object};

    connect() {
        const root = am5.Root.new(this.element);
        this.barChart = root;
        setLocale(root, this.chartValue.language);

        // set our theme
        root.setThemes([MysteryMindsTheme.new(root)]);
        this.initializeChart(root);
    }

    disconnect() {
        this.barChart.dispose();
    }

    initializeChart(root) {
        const chart = this.createChart(root);
        this.createAxes(root, chart);
        this.enableCursor(root, chart);

        let series = this.createSeries(root, chart);
        this.enableToolTips(root, series);

        // Only on row charts
        if (this.chartValue.horizontalView) {
            this.enableBullets(root, series)
        }

        this.enableGradient(root, series);
        this.enableExport(root);

        this.setupChart(chart, series);
    }

    createChart(root) {
        return root.container.children.push(
            am5xy.XYChart.new(root, {
                layout: root.verticalLayout,
            })
        );
    }

    createAxes(root, chart) {
        const isHorizontal = this.chartValue.horizontalView;

        const categoryAxis = chart[isHorizontal ? "yAxes" : "xAxes"].push(
            am5xy.CategoryAxis.new(root, {
                renderer: isHorizontal ? am5xy.AxisRendererY.new(root, { "minGridDistance": 10 }) : am5xy.AxisRendererX.new(root, {}),
                categoryField: this.chartValue.categoryName,
            })
        );

        chart[isHorizontal ? "xAxes" : "yAxes"].push(
            am5xy.ValueAxis.new(root, {
                numberFormat: this.chartValue.valueNumberFormat,
                renderer: isHorizontal ? am5xy.AxisRendererX.new(root, {}) : am5xy.AxisRendererY.new(root, {}),
                maxPrecision: 0,
                max: this.chartValue.valueMax ?? undefined,
                min: 0,
            })
        );

        // Apply label padding
        categoryAxis.get("renderer").labels.template.setAll(
            isHorizontal
                ? { paddingRight: 10, oversizedBehavior: "truncate", maxWidth: 180, } // Y-axis padding for horizontal chart
                : { paddingTop: 10, oversizedBehavior: "wrap", maxWidth: 160, textAlign: "center" } // X-axis padding for vertical chart
        );

        categoryAxis.data.setAll(this.chartValue.data);
    }

    createSeries(root, chart) {
        return chart.series.push(
            am5xy.ColumnSeries.new(root, {
                xAxis: chart.xAxes.getIndex(0),
                yAxis: chart.yAxes.getIndex(0),
                valueXField: this.chartValue.horizontalView ? this.chartValue.valueName : undefined,
                valueYField: this.chartValue.horizontalView ? undefined : this.chartValue.valueName,
                categoryYField: this.chartValue.horizontalView ? this.chartValue.categoryName : undefined,
                categoryXField: this.chartValue.horizontalView ? undefined : this.chartValue.categoryName,
            })
        );
    }

    enableCursor(root, chart) {
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: 'none',
        }));

        cursor.lineX.setAll({
            stroke: am5.color(0x626b85),
            strokeWidth: 2,
            strokeDasharray: [5, 5],
        });

        // hide the lineY cursor
        cursor.lineY.setAll({
            visible: false,
        });
    }

    enableToolTips(root, series) {
        let tooltip = am5.Tooltip.new(root, {
            labelText: this.chartValue.tooltipText,
            pointerOrientation: "horizontal",
        });

        tooltip.get("background").setAll({
            stroke: am5.color(0x231f20),
            fillOpacity: 1,
            strokeOpacity: 0.6
        });

        series.set('tooltip', tooltip);
    }

    enableBullets(root, series) {
        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "{valueX}",
                    populateText: true
                })
            });
        });
    }

    enableGradient(root, series) {
        series.columns.template.set("fillGradient", am5.LinearGradient.new(root, {
            stops: [{
                opacity: 0.8
            }, {
                opacity: 0.3
            }],
            rotation: 90
        }));
    }

    setupChart(chart, series) {
        const isHorizontal = this.chartValue.horizontalView;

        // for charts where the label is on the left side
        // make sure to increase the height of the chart
        // so all labels and items are visible
        // source: https://www.amcharts.com/docs/v5/tutorials/auto-adjusting-chart-height-based-on-a-number-of-data-items/
        if(isHorizontal) {
            let cellSize = 35;

            series.events.on("datavalidated", function(ev) {
                let series = ev.target;
                let chart = series.chart;
                let xAxis = chart.xAxes.getIndex(0);

                // calculate how we need to adjust chart height
                let chartHeight = series.data.length * cellSize + xAxis.height() + chart.get("paddingTop", 0) + chart.get("paddingBottom", 0);

                // set it on chart's container
                chart.root.dom.style.height = chartHeight + "px";
            });
        }

        series.set("fill", am5.color(this.chartValue.color));
        series.set("stroke", am5.color(this.chartValue.color));
        series.set("strokeWidth", 3);

        series.data.setAll(this.chartValue.data);

        series.appear(3500, 100);
        chart.appear(3500, 100);
    }

    enableExport(root) {
        am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            filePrefix: this.chartValue.exportFileName,
            dataSource: this.chartValue.data,
            jpgOptions: {disabled: true},
            pdfOptions: {addURL: false},
            pdfdataOptions: {disabled: true},
            csvOptions: {disabled: true},
            jsonOptions: {disabled: true},
            htmlOptions: {disabled: true},
            printOptions: {disabled: true},
        });
    }
}
