<script setup>
import { computed, inject } from "vue";
import AddStepButton from "./AddStepButton.vue";
import RemoveBlockButton from "./RemoveBlockButton.vue";
import Steps from "../Step/Steps.vue";
import BlockLabel from "./BlockLabel.vue";

const store = inject('store');
const {blockIndex} = defineProps(['blockIndex']);
const block = computed(() => store.journey.blocks[blockIndex]);
const blockBorder = computed(() => store.activeBlockIndex === blockIndex ? '1px solid red' : null);
</script>

<template>
    <div class="journey-block" :style="{ border: blockBorder}">
        <BlockLabel :block-index="blockIndex"/>
        <Steps :block-index="blockIndex"/>
        <AddStepButton :block-index="blockIndex"/>
        <RemoveBlockButton :block-index="blockIndex"/>
    </div>
</template>
