import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        // Create the IntersectionObserver instance
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            root: null,
            threshold: 1,
            rootMargin: '0px 0px 100px 0px', // as soon as the "about headline" is visible
        });

        // Observe the "about-heading"
        const aboutHeadline = document.getElementById('about-heading');
        this.observer.observe(aboutHeadline);
    }

    // Add or remove the sticky class depending on the position of the image preview
    handleIntersection(entries) {
        entries.forEach(entry => {
            // Show if the about-headline is scrolled into the visible area
            if(entry.isIntersecting || (!entry.isIntersecting && entry.boundingClientRect.top < 0)) {
                this.element.classList.add('is--hidden');
            }
            else {
                this.element.classList.remove('is--hidden');
            }
        });
    }

    // Stop observing when the controller is disconnected
    disconnect() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
