<script setup>
import { inject } from "vue";

const store = inject('store');
const { blockIndex, stepIndex } = defineProps(['blockIndex', 'stepIndex']);
</script>

<template>
  <button class="btn btn--primary btn--xs" @click="store.editStep(blockIndex, stepIndex)">
    Edit
  </button>
</template>
