export function disableSubmitButtons(event) {
    let submitButtonList = document.querySelectorAll('[type="submit"]');
    let submitButton = event.detail?.formSubmission?.submitter ?? null;

    submitButtonList.forEach(function (button) {
        button.classList.add('show--enabled');
        button.setAttribute('disabled', '');
    });

    // show loading animation on clicked submit button
    if (submitButton !== null) {
        submitButton.setAttribute('disabled', '');
        submitButton.classList.remove('show--enabled');
        submitButton.classList.add('is--loading');
    }
}

// enable buttons **and remove** loading animation
export function enableSubmitButtons(event) {
    let submitButtonList = document.querySelectorAll('[type="submit"]');
    let submitButton = event.detail?.formSubmission?.submitter ?? null;

    submitButtonList.forEach(function (button) {
        button.classList.remove('show--enabled');
        button.removeAttribute('disabled', '');
    });

    if (submitButton !== null) {
        // remove loading animation from clicked submit button
        submitButton.classList.remove('is--loading');
    }
}

export function showSaveSuccessAnimation(elementToShowOn) {
    elementToShowOn.classList.add('is--success');

    setTimeout(function() {
        elementToShowOn.classList.remove('is--success');
        elementToShowOn.removeAttribute("success");
    }, 2500); // make sure this timeout is the same as .card__saved animation duration
}