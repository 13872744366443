import { reactive, computed } from 'vue';
import { arrayMoveMutable } from 'array-move';
import { ulid } from 'ulid';
import axios from 'axios';
import stepSchemas from './stepSchemas';

export default function useJourneyStore(initialJourney, saveUrl) {
  const store = reactive({
    journey: {
      // A list of blocks, each containing a list of steps.
      blocks: [...initialJourney.blocks]
    },

    // Store the original initialJourney in a non-reactive variable.
    // This ensures we keep the original state regardless of what changes happen.
    lastSavedJourneyAsString: JSON.stringify(initialJourney),

    // Whether anything in the journey has been modified.
    isDirty: computed(() => JSON.stringify(store.journey) !== store.lastSavedJourneyAsString),

    // The active block (if any).
    activeBlockIndex: null,

    // The currently edited block (if any).
    editedBlockIndex: null,

    // The active step within the active block (if any).
    activeStepIndex: null,

    activeBlock: computed(() => {
      if (store.activeBlockIndex === null) {
        return null;
      }

      return store.journey.blocks[store.activeBlockIndex];
    }),

    // The currently active step object.
    activeStep: computed(() => {
      if (store.activeBlockIndex === null || store.activeStepIndex === null) {
        return null;
      }

      return store.journey.blocks[store.activeBlockIndex].steps[store.activeStepIndex];
    }),

    // The currently active step's type.
    activeStepType: computed(() => {
      if (store.activeStep === null) {
        return null;
      }

      return store.activeStep.type;
    }),

    addBlock: () => {
      store.journey.blocks.push({
        id: ulid(),
        after: store.journey.blocks.length > 0 ? store.journey.blocks[store.journey.blocks.length - 1].after + 1 : 0,
        steps: [],
      });
      store.activeBlockIndex = store.journey.blocks.length - 1;
      store.activeStepIndex = null;
    },

    editBlock: (blockIndex) => {
      store.editedBlockIndex = blockIndex;
    },

    updateBlock: (blockIndex, newAfter) => {
      store.clearBlockEditing();

      const oldAfter = store.journey.blocks[blockIndex].after;

      // See if anything changed at all.
      if (oldAfter === newAfter) {
        console.error('Not moving because nothing changed');
        return;
      }

      // Store the new value.
      store.journey.blocks[blockIndex].after = newAfter;

      // We're moving the block upwards.
      if (newAfter < oldAfter) {
        if (blockIndex === 0) {
          // If we're already at the beginning of the list, there's nothing to do.
          console.error('Not moving upwards because already at the beginning of the list');
        } else {
          // Move upwards block by block.
          for (let i = blockIndex - 1; i >= 0; i--) {
            // If we arrived at a block that should remain above the edited block, stop and move the edited block below it.
            if (store.journey.blocks[i].after <= newAfter) {
              if (blockIndex !== i + 1) {
                console.error('Moving upwards from ' + blockIndex + ' to ' + (i + 1));
                arrayMoveMutable(store.journey.blocks, blockIndex, i + 1);
              } else {
                console.error('Not moving upwards because already at the right place');
              }
              return;
            }
          }

          // We've reached the beginning of the list - move to the top.
          console.error('Moving upwards from ' + blockIndex + ' to 0');
          arrayMoveMutable(store.journey.blocks, blockIndex, 0);
        }
      }

      // We're moving the block downwards.
      if (newAfter > oldAfter) {
        if (blockIndex === store.journey.blocks.length - 1) {
          // If we're already at the end of the list, there's nothing to do.
          console.error('Not moving downwards because already at the end of the list');
        } else {
          // Move upwards block by block.
          for (let i = blockIndex + 1; i <= store.journey.blocks.length - 1; i++) {
            // If we arrived at a block that should remain below the edited block, stop and move the edited block above it.
            if (store.journey.blocks[i].after >= newAfter) {
              if (blockIndex !== i - 1) {
                console.error('Moving downwards from ' + blockIndex + ' to ' + (i - 1));
                arrayMoveMutable(store.journey.blocks, blockIndex, i - 1);
              } else {
                console.error('Not moving downwards because already at the right place');
              }
              return;
            }
          }

          // We've reached the end of the list - move to the bottom.
          console.error('Moving downwards from ' + blockIndex + ' to the end of the list ' + (store.journey.blocks.length - 1));
          arrayMoveMutable(store.journey.blocks, blockIndex, (store.journey.blocks.length - 1));
        }
      }
    },

    clearBlockEditing: () => {
      store.editedBlockIndex = null;
    },

    removeBlock: (blockIndex) => {
      store.activeBlockIndex = null;
      store.activeStepIndex = null;
      store.journey.blocks.splice(blockIndex, 1);
    },

    addStep: (blockIndex, type) => {
      const createStepFunction = stepSchemas[type];

      store.journey.blocks[blockIndex].steps.push(createStepFunction());
      store.activeBlockIndex = blockIndex;
      store.activeStepIndex = store.journey.blocks[blockIndex].steps.length - 1;
    },

    // Edit a step in the main area.
    editStep: (blockIndex, stepIndex) => {
      store.activeBlockIndex = blockIndex;
      store.activeStepIndex = stepIndex;
    },

    // Remove a step from a block.
    removeStep: (blockIndex, stepIndex) => {
      store.journey.blocks[blockIndex].steps.splice(stepIndex, 1);
      store.activeStepIndex = null;
    },

    // Reset function that restores the journey to its original (last saved) state.
    resetJourney: () => {
      const blockIdBeforeReset = store.activeBlock?.id;
      const stepIdBeforeReset = store.activeStep?.id;

      store.journey = {...JSON.parse(store.lastSavedJourneyAsString)};

      // Let's see if we can find the previously selected step or at least the previously selected block.
      let foundBlockIndex = null;
      for (const [blockIndex, block] of store.journey.blocks.entries()) {
        for (const [stepIndex, step] of block.steps.entries()) {
          if (step.id === stepIdBeforeReset) {
            store.activeBlockIndex = blockIndex;
            store.activeStepIndex = stepIndex;
            return;
          }
        }

        // Maybe we found the block, at least? Remember it, in case we never find the active step.
        if (block.id === blockIdBeforeReset) {
          foundBlockIndex = blockIndex;
        }
      }

      store.activeBlockIndex = foundBlockIndex;
      store.activeStepIndex = null;
    },

    // Save the journey to the database.
    saveJourney: () => {
      axios.post(saveUrl, { blocks: store.journey.blocks})
          .then(() => {
            store.lastSavedJourneyAsString = JSON.stringify(store.journey);
          })
          .catch(error => {
            alert(error);
          });
    },
  });

  return store;
};
