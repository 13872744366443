import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['buttonDiv', 'successDiv'];

    async connect() {
        this.registration = null;

        if (await this.shouldShowSubscribeButton()) {
            this.buttonDivTarget.style.display = 'block';
        }
    }

    async shouldShowSubscribeButton() {
        if (!'serviceWorker' in navigator) {
            console.debug('Not showing push notification button, because service worker is not supported by this browser');
            return false;
        }

        console.debug('Registering service worker...');
        this.registration = await navigator.serviceWorker.register('/service-worker.js');

        const permission = Notification.permission;
        if (permission === 'denied') {
            // Don't ask for permission again.
            console.debug('Not showing push notification button, because denied');
            return false;
        }

        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
            // Ask for permission.
            console.debug('Showing push notification button, because there is no service worker registration');
            return true;
        }

        const subscription = await registration.pushManager.getSubscription();
        if (!subscription) {
            // Ask for permission.
            console.debug('Showing push notification button, because there is no push manager subscription');
            return true;
        }

        // We're all set up already.
        console.debug('Not showing push notification button, because notifications are set up already');
        return false;
    }

    async setupSubscription() {
        try {
            await this.requestNotificationPermission();
            await this.subscribeUserToPush();
        } catch (err) {
            this.buttonDivTarget.style.display = 'none';
            return;
        }

        console.debug('Push notification subscription complete!');
        this.buttonDivTarget.style.display = 'none';
        this.successDivTarget.style.display = 'block';
    }

    async requestNotificationPermission() {
        console.debug('Requesting notification permission...');
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
            throw new Error('Notification permission not granted');
        }

        console.debug('Notification permission granted');
    }

    async subscribeUserToPush() {
        console.debug('Subscribing to push notifications...');
        const subscription = await this.registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'BOW2Ca7Yru3kFQUAu2dywPBF9UL8S_iu31AAiB6Zo_zBnds5EkibG7fzYI_TBsMnrFTixJtW2GYZXpR73wuBxhY',
        });

        console.debug('Registering push notification subscription in the backend...');

        await fetch('/subscribe-to-push-notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(subscription),
        });

        console.debug('Push notification subscription registered in the backend');
    }
}
