<script setup>
import { inject } from 'vue';
import EditSendEmailStep from "./EditSendEmailStep.vue";
import EditNotifySuccessManagerStep from "./EditNotifySuccessManagerStep.vue";

const store = inject('store');
</script>

<template>
    <EditSendEmailStep v-if="store.activeStepType === 'SendEmailStep'"/>
    <EditNotifySuccessManagerStep v-if="store.activeStepType === 'NotifySuccessManagerStep'"/>
</template>
