<script setup>
import {computed, inject} from "vue";
import {VueDraggable} from "vue-draggable-plus";
import Step from "./Step.vue";

const store = inject('store');
const { blockIndex } = defineProps(['blockIndex']);
const block = computed(() => store.journey.blocks[blockIndex]);
</script>

<template>
    <VueDraggable v-model="block.steps" group="steps">
        <div v-for="(step, stepIndex) in block.steps" :key="step.id">
            <Step :block-index="blockIndex" :step-index="stepIndex"/>
        </div>
    </VueDraggable>
</template>
