<script setup>
import { inject } from "vue";

const store = inject('store');
</script>

<template>
  <div class="journey-block__add">
    <button class="btn btn--primary" @click="store.addBlock()">
      <svg focusable="false" aria-hidden="true" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor">
        <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
      </svg>
      Add block
    </button>
  </div>
</template>
